import React, { useState, useEffect } from "react";

import { apiFetch } from "../../services/fetch";
import { Autocomplete, TextField } from "@mui/material";
import { DrugClass } from "../../constants/types";

type DrugClassSelectProps = {
    selected: any;
    setSelected: (arg0: any) => void;
    multiple?: boolean;
    label?: string;
}

export default function DrugClassSelect({ selected, setSelected, multiple, label }: DrugClassSelectProps) {
    const [drugClasses, setDrugClasses] = useState<DrugClass[]>([]);
    const [error, setError] = useState<null | string>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const drugClassesResponse = await apiFetch(`/drugclasses`)
                setDrugClasses(drugClassesResponse);
            } catch (e) {
                setError("Failed to load data");
            }
        };

        fetchData();
    }, []);

    return (
        <Autocomplete
            multiple={multiple}
            options={drugClasses}
            sx={{
                minWidth: "180px",
            }}
            getOptionLabel={(option) => option.Name}
            isOptionEqualToValue={(option, value) =>
                option.ID === value.ID
            }
            value={selected}
            onChange={(_, value) => {
                setSelected(value)
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        error={error !== null}
                        size="small"
                        label={label}
                    />
                );
            }}
        />
    )

}
